import React from 'react';
import { Button } from 'antd';

import { Icon } from '../../../components';
import PremisesSettlementsService from '../../../services/premisesSettlementsService';

export const BankTransferFormGenerator = ({
  investmentId,
  premisesId,
  settlementData,
}) => {
  const generatePdf = () => {
    const premisesSettlementsService = new PremisesSettlementsService(
      investmentId,
      premisesId
    );

    premisesSettlementsService
      .getBankTransferForm(settlementData.id)
      .then((data) => {
        const a = document.createElement('a');
        a.href = `data:application/octet-stream;base64,${data.content}`;
        a.download = `${settlementData.name}_druk.pdf`;
        a.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Button
      className='button-secondary'
      onClick={generatePdf}
      style={{ minWidth: 0, maxWidth: 48 }}
    >
      <Icon name='pdf' style={{ paddingLeft: 0 }} />
    </Button>
  );
};
