import BaseService from './baseService';
import httpService from '../helpers/httpService';

class PremisesSettlementsService extends BaseService {
  constructor(investmentId, premisesId) {
    super();

    this.url = `/api/community/investment/${investmentId}/premises/${premisesId}/settlements`;
  }

  async getCosts(settlementId) {
    return httpService.get(`${this.url}/${settlementId}/costs`, null, true);
  }

  async getBankTransferForm(settlementId) {
    return httpService.get(
      `${this.url}/${settlementId}/bank-transfer`,
      null,
      true
    );
  }
}

export default PremisesSettlementsService;
